import React, {useContext} from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';

const Header: React.FunctionComponent = () => {

  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  const signIn = () => {
    navigate('/signin')
  }

  const signUp = () => {
    navigate('/signup')
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/"><span className="text-warning">Cost</span> Worksheet</NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
            </ul>
            <div className="text-end">
              <button type="button" onClick={signIn} className="btn btn-outline-light me-2">Login</button>
              <button type="button" onClick={signUp} className="btn btn-warning">Sign-up</button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
