import Header from '../components/header'
import Footer from '../components/footer'
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Header />
      <main className="flex-shrink-0">
        <div className="container">
          <h1 className="mt-5">Cost Worksheet</h1>
          <p></p>
          <p className="lead">AI-powered tools to manage cost and budget for your project. Useful tips and important tasks to keep your cost under control.</p>
          <ul>
            <li>Buying a home</li>
            <li>Leasing a car</li>
            <li>Running a team</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
